@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
}

body {
    /* background: rgb(230, 230, 228); */
    background: linear-gradient(#FAF9F6, #ffecc6);
}

html {
    scroll-behavior: smooth;
}


a{
    text-decoration: none;
    color: inherit;
    line-height: 1;
    cursor: pointer;
}

.container {
    padding-left: 10%;
    padding-right: 10%;
}

.btn{
    background: #fff;
    color: #212121;
    padding: 14px 25px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    outline: 0;
}