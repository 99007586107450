  .container {
    text-align: center;
    width: 100%;
    margin: auto;
  }
  
  .skill{
    padding-top: 60px;
  }

  /* Headings Styling */
  h2 {
    font-size: 24px;
    color: #888;
    margin-bottom: 10px;
    font-weight: 300;
  }
  
  h1 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 40px;
    color: #333;
  }
  
  /* Grid Layout for Skills */
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two-column layout */
    gap: 30px;
    margin-top: 20px;
    align-content: center;
  }
  
  .skill-box {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    text-align: left;
  }
  
  .skill-box:hover {
    transform: translateY(-5px); /* Lift effect on hover */
  }
  
  .skill-box h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .skill-box ul {
    list-style: none;
    padding: 0;
  }
  
  .skill-box li {
    font-size: 18px;
    margin: 8px 0;
    display: flex;
    align-items: center;
  }
  
  .skill-box span {
    font-size: 20px;
    color: #c19a6b;
    margin-right: 10px;
  }
  
  /* Responsive Layout */
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr; /* Single-column layout for smaller screens */
    }
  }
  