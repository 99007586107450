/* Contact Form Container */
.contact-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 0px 20px;
    background-color: #f8f4e5; /* Light cream background */
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow */
    font-family: 'Arial', sans-serif;
    padding-top: 60px;
    padding-bottom: 30px;
}

/* Heading Styling */
.contact-heading {
    text-align: center;
    color: #333;
    font-size: 2rem;
    margin-bottom: 15px;
    font-weight: bold;
}

/* Success Message Styling */
.success-message {
    text-align: center;
    color: #4caf50; /* Green color */
    font-size: 1.2rem;
    margin-top: 20px;
}

/* Form Styling */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Form Group */
.form-group {
    display: flex;
    flex-direction: column;
}

/* Labels */
.form-label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
}

/* Inputs and Textareas */
.form-input,
.form-textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1rem;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Light shadow */
}

/* Textarea Specific Styles */
.form-textarea {
    height: 120px;
    resize: none; /* Prevent resizing */
}

/* Submit Button */
.submit-button {
    width: 100%;
    padding: 15px;
    background-color: #bb9351; /* Warm brown */
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #a4793f; /* Darker brown on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-container {
        margin: 20px;
        padding: 20px;
        padding: 10px 20px;
        margin: 30px 20%;
    }

    .contact-heading {
        font-size: 1.8rem;
    }

    .form-input,
    .form-textarea {
        font-size: 0.9rem;
    }

    .submit-button {
        font-size: 1rem;
    }
}

@media (max-width: 450px) {
    .contact-container {
        margin: 20px;
        padding: 20px;
        padding: 10px 20px;
        margin: 30px 8%;
    }

    .contact-heading {
        font-size: 1.8rem;
    }

    .form-input,
    .form-textarea {
        font-size: 0.9rem;
    }

    .submit-button {
        font-size: 1rem;
    }
}
