/* Footer Container */
.footer {
    background-color: #f8f4e5; /* Light cream background to match theme */
    padding: 40px 20px;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
    font-family: 'Arial', sans-serif;
}

/* Footer Content Layout */
.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

/* Footer Sections */
.footer-section {
    max-width: 300px;
    margin: 10px;
}

.footer-section h2 {
    color: #333;
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: bold;
}

.footer-section p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 10px;
}

/* Links Styling */
.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin: 5px 0;
}

.footer-section ul li a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-section ul li a:hover {
    color: #bb9351; /* Warm brown hover effect */
}

/* Footer Bottom Section */
.footer-bottom {
    text-align: center;
    color: #333;
    margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        max-width: 100%;
        text-align: center;
    }
}
