/* Right Section Styling */
.right {
    padding: 10px 100px;
}

/* Main Container Styling */
.main {
    background-color: transparent;
    align-items: center;
    justify-content: space-between;
    margin: 50px 10%;
    display: flex;
    flex-direction: row; /* Row layout for larger screens */
    border-radius: 20px;
    padding-top: 60px;
}

.content {
    list-style: none;
    font-family: "Sans-serif", Helvetica;
    padding: 10px;
    color: #454545;
}

/* Headings and Subheadings */
.heading {
    font-size: 2.5rem;
    color: #333;
}

.subheading {
    font-size: 1.8rem;
    color: #555;
    margin-bottom: 20px;
}

p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 15px;
}

/* Cards Section */
.cards {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
    padding: 20px;
    align-items: center;
}

/* Image Styling */
.imageofme {
    width: 425px;
    max-width: 425px;
    border-radius: 30px;
    margin-left: 20px;
}

/* Education Section */
.education {
    background-color: white;
}

/* About Section */
.about {
    background-color: #bb9351;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    height: 220px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    background-color: bisque;
    border-style: solid;
    border-color: #333;
}

/* Heading Inside About Section */
h3 {
    font-size: 1.5rem;
    color: #444;
}

/* Paragraph Styling */
p {
    color: #555;
    line-height: 1.6;
}

/* Responsive Design for Tablets and Smaller Screens */
@media (max-width: 1100px) {
    .main {
        flex-direction: column; /* Stack content vertically */
        margin: 20px; /* Adjust margins for smaller screens */
    }

    .cards {
        flex-direction: column; /* Stack cards vertically */
        gap: 15px; /* Reduce gap between cards */
    }

    .right {
        padding: 10px 20px; /* Reduce padding */
    }

    .imageofme {
        width: 300px;
        margin: 10px 0; /* Adjust margins for smaller devices */
    }

    .heading {
        font-size: 2rem;
    }

    .subheading {
        font-size: 1.5rem;
    }

    p {
        font-size: 1rem;
    }

    .about {
        width: 100%; /* Full width for small screens */
        height: auto; /* Auto height to fit content */
    }
}

/* Mobile-Friendly Design for Phones (Max Width: 400px) */
@media (max-width: 400px) {
    .main {
        padding: 10px;
        margin: 10px 5px;
    }

    .imageofme {
        width: 250px;
    }

    .heading {
        font-size: 1.8rem;
    }

    .subheading {
        font-size: 1.2rem;
    }

    p {
        font-size: 0.9rem;
    }

    .cards {
        gap: 10px;
    }

    .about {
        padding: 15px;
    }

    .about h3 {
        font-size: 1.2rem;
    }

    .about p {
        font-size: 0.9rem;
    }
}
